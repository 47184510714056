import React from "react"

import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"

const IndexPage = () => {
  return (
    <Layout>
      <Container fluid className="main-contain">
        <Row>
          <Col>
            <ul className="links">
              <li>
                <a href="https://soundcloud.com/ebbcrater">
                  Ebb Crater on SoundCloud
                </a>
              </li>
              <li>
                <a href="https://tiotype.com">tiotype.com</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
